<template>
    <div class="inner-section">
      <card>
        <!-- search section start -->
        <template v-slot:searchHeaderTitle>
          <h4 class="card-title">{{ $t('teaGardenPduService.student_scholarship_report') }}</h4>
        </template>
        <template v-slot:searchBody>
        <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
        <b-form @submit.prevent="handleSubmit(searchData)" @reset.prevent="reset" autocomplete="off">
          <b-row>
            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                <ValidationProvider name="Fiscal Year" vid="fiscal_year_id" rules="required|min_value:1">
                  <b-form-group
                      label-for="fiscal_year_id"
                      slot-scope="{ valid, errors }"
                      >
                      <template v-slot:label>
                      {{ $t('globalTrans.fiscal_year') }} <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                      plain
                      v-model="search.fiscal_year_id"
                      :options="fiscalYearList"
                      id="fiscal_year_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                      >
                      <template v-slot:first>
                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                      </b-form-select>
                      <div class="invalid-feedback d-block">
                      {{ errors[0] }}
                      </div>
                  </b-form-group>
                </ValidationProvider>
            </b-col>
            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                <ValidationProvider name="Trust Type" vid="trust_type" rules="required|min_value:1">
                  <b-form-group
                      label-for="trust_type"
                      slot-scope="{ valid, errors }"
                      >
                      <template v-slot:label>
                      {{ $t('teaGardenPduService.trust_type') }} <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                      plain
                      v-model="search.trust_type"
                      :options="trustTypeList"
                      id="trust_type"
                      :state="errors[0] ? false : (valid ? true : null)"
                      >
                      <template v-slot:first>
                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                      </b-form-select>
                      <div class="invalid-feedback d-block">
                      {{ errors[0] }}
                      </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
            <!-- Garden list -->
            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
              <ValidationProvider name="Garden Name" vid="garden_id">
                <b-form-group
                    label-for="garden_id"
                    slot-scope="{ valid, errors }"
                    >
                    <template v-slot:label>
                    {{ $t('teaGardenPduService.garden_name') }}
                    </template>
                    <b-form-select
                    plain
                    v-model="search.garden_id"
                    :options="gardenList"
                    id="garden_id"
                    :state="errors[0] ? false : (valid ? true : null)"
                    >
                    <template v-slot:first>
                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                    </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                    {{ errors[0] }}
                    </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                <ValidationProvider name="Application Status" vid="status">
                  <b-form-group
                      label-for="status"
                      slot-scope="{ valid, errors }"
                      >
                      <template v-slot:label>
                      {{ $t('teaGardenPduService.application_status') }} <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                      plain
                      v-model="search.status"
                      :options="applicationStatusList"
                      id="status"
                      :state="errors[0] ? false : (valid ? true : null)"
                      >
                      <template v-slot:first>
                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                      </b-form-select>
                      <div class="invalid-feedback d-block">
                      {{ errors[0] }}
                      </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
              <b-button size="sm" variant="primary" class="mt-20" type="submit">
                <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
        </ValidationObserver>
        </template>
        <!-- search section end -->
      </card>
      <b-row>
        <b-col md="12" v-if="showData">
          <body-card>
                <!-- table section start -->
                <template v-slot:headerTitle>
                  <h4 class="card-title">{{ $t('teaGardenPduService.student_scholarship_report') }} {{ $t('globalTrans.list') }}</h4>
                </template>
                <template v-slot:headerAction>
                  <a href="javascript:" class="btn-add" @click="pdfExport">
                     {{ $t('globalTrans.export_pdf') }}
                  </a>
                </template>
                <template v-slot:body>
                  <b-overlay :show="loadingState">
                    <b-row>
                      <b-col>
                        <list-report-head :base-url="teaGardenServiceBaseUrl" uri="/configuration/report-heading/detail" :org-id="5" :service-id="17">
                          <template v-slot:projectNameSlot>
                            {{ }}
                          </template>
                          {{ $t('teaGardenPduService.student_scholarship_report') }}
                        </list-report-head>
                      </b-col>
                    </b-row>
                    <template>
                      <div class="text-black mb-4">
                        <b-row>
                          <b-col md="8">
                            {{ $t('globalTrans.fiscal_year') }}: <strong>{{ search.fiscal_year_id ? getFiscalYearList(search.fiscal_year_id) : $t('globalTrans.all') }}</strong>
                          </b-col>
                          <b-col md="4">
                            {{ $t('teaGardenPduService.trust_type') }}: <strong>{{ search.trust_type ? search.trust_type === 1 ? (currentLocale === 'en' ? 'Primary' : 'প্রাথমিক') :  (currentLocale === 'en' ? 'Secondary' : 'মাধ্যমিক') : $t('globalTrans.all') }}</strong>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col md="8">
                            {{ $t('teaGardenPduService.garden_name') }}: <strong>{{ search.garden_id ? getGardenName(search.garden_id) : $t('globalTrans.all') }}</strong>
                          </b-col>
                          <b-col md="4">
                            {{ $t('teaGardenPduService.application_status') }}: <strong>{{ search.status ? search.status === 5 ? (currentLocale === 'en' ? 'Approve' : 'অনুমোদন') : (currentLocale === 'en' ? 'Reject' : 'প্রত্যাখ্যান') : $t('globalTrans.all') }}</strong>
                          </b-col>
                        </b-row>
                      </div>
                      <div>
                        <div class="table-responsive">
                          <b-table-simple class="tg mt-3" bordered striped hover small caption-top responsive :emptyText="$t('globalTrans.noDataFound')">
                            <b-thead>
                              <b-tr>
                                <b-th class="text-center">{{ $t('globalTrans.sl_no') }}</b-th>
                                <b-th class="text-center">{{ $t('teaGardenPduService.application_id') }}</b-th>
                                <b-th class="text-center">{{ $t('teaGardenPduService.trust_type') }}</b-th>
                                <b-th class="text-center">{{ $t('teaGardenPduService.garden_name') }}</b-th>
                                <b-th class="text-center">{{ $t('teaGardenPduService.school_name') }}</b-th>
                                <b-th class="text-center" v-if="search.trust_type === 1">{{ $t('teaGardenPduService.applicant_number') }}</b-th>
                                <b-th class="text-center" v-if="search.trust_type === 2">{{ $t('teaGardenPduService.student_name') }}</b-th>
                                <b-th class="text-center">{{ $t('globalTrans.status') }}</b-th>
                              </b-tr>
                            </b-thead>
                            <b-tbody>
                              <b-tr v-for="(item, index) in datas" :key="index">
                                <b-td class="text-center">{{ $n(index + 1) }}</b-td>
                                <b-td class="text-center">{{ item.app_id }}</b-td>
                                <b-td class="text-center">{{ item.trust_type === 1 ? (currentLocale === 'en' ? 'Primary' : 'প্রাথমিক') :  (currentLocale === 'en' ? 'Secondary' : 'মাধ্যমিক') }}</b-td>
                                <b-td class="text-center">{{ getGardenName(item.garden_id) }}</b-td>
                                <b-td class="text-center">{{ getSchoolName(item.garden_id) }}</b-td>
                                <b-td class="text-center" v-if="item.details_count">{{ $n(item.details_count, { useGrouping: false}) }}</b-td>
                                <b-td class="text-center" v-if="currentLocale === 'en' ? item.applicant_name_en : item.applicant_name_bn">{{ currentLocale === 'en' ? item.applicant_name_en : item.applicant_name_bn }}</b-td>
                                <b-td class="text-center">{{ item.status === 5 ? (currentLocale === 'en' ? 'Approve' : 'অনুমোদন') : (currentLocale === 'en' ? 'Reject' : 'প্রত্যাখ্যান') }}</b-td>
                              </b-tr>
                              <b-tr v-if="Object.keys(datas).length === 0">
                                <th colspan="7" class="text-center">{{ $t('globalTrans.no_data_found') }}</th>
                              </b-tr>
                            </b-tbody>
                          </b-table-simple>
                        </div>
                      </div>
                    </template>
                  </b-overlay>
                </template>
                <!-- table section end -->
          </body-card>
        </b-col>
      </b-row>
    </div>
  </template>
  <script>
  import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
  import { studentScholarshipApi } from '../../../api/routes'
  import ModalBaseMasterList from '@/mixins/list'
  import ListReportHead from '@/components/custom/TeaGardenReportHead.vue'
  import excel from 'vue-excel-export'
  import Vue from 'vue'
  Vue.use(excel)
  export default {
    mixins: [ModalBaseMasterList],
    components: {
      ListReportHead
    },
    data () {
      return {
        teaGardenServiceBaseUrl: teaGardenServiceBaseUrl,
        sortBy: '',
        search: {
          fiscal_year_id: this.$store.state.currentFiscalYearId,
          trust_type: 0,
          garden_id: 0,
          status: 0,
          limit: 20
        },
        editItemId: '',
        sortDesc: true,
        sortDirection: 'desc',
        datas: [],
        showData: false
      }
    },
    created () {
    },
    watch: {
      'search.limit': function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.loadData()
        }
      }
    },
    computed: {
      gardenList () {
        return this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.filter(item => item.status === 1)
      },
      formTitle () {
        return (this.editItemId === 0) ? this.$t('priceMonitoring.market_inspection_report') + ' ' + this.$t('globalTrans.entry') : this.$t('priceMonitoring.market_inspection_report') + ' ' + this.$t('globalTrans.modify')
      },
      currentLocale () {
        return this.$i18n.locale
      },
      fiscalYearList () {
        return this.$store.state.CommonService.commonObj.fiscalYearList.filter(item => item.status === 1)
      },
      trustTypeList () {
        return [
            { value: 1, text: this.currentLocale === 'en' ? 'Primary' : 'প্রাথমিক' },
            { value: 2, text: this.currentLocale === 'en' ? 'Secondary' : 'মাধ্যমিক' }
          ]
        },
       applicationStatusList () {
        return [
            { value: 5, text: this.currentLocale === 'en' ? 'Approve' : 'অনুমোদন' },
            { value: 6, text: this.currentLocale === 'en' ? 'Reject' : 'প্রত্যাখ্যান' }
          ]
        }
    },
    methods: {
      async pdfExport () {
        const params = Object.assign({ request_type: 'pdf', local: this.$i18n.locale, org_id: 5 }, this.search)
        const service = this.$store.state.TeaGardenService.commonObj.masterServiceList.find(item => item.value === 28)
        if (service !== undefined) {
          if (service.office_type_id) {
            params.office_type_id = service.office_type_id
          }
          if (service.office_id) {
            params.office_id = service.office_id
          }
        }
        this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        const result = await RestApi.getPdfData(teaGardenServiceBaseUrl, studentScholarshipApi, params)
        var blob = new Blob([result], {
          type: 'application/pdf'
        })
        var url = window.URL.createObjectURL(blob)
        window.open(url).print()
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      },
      searchData () {
        this.loadData()
      },
      async loadData () {
        this.showData = true
        const params = Object.assign({}, this.search)
        this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        const result = await RestApi.getData(teaGardenServiceBaseUrl, studentScholarshipApi, params)
        if (result.success) {
          this.datas = result.data
          this.$store.dispatch('setList', this.datas)
        } else {
          this.$store.dispatch('setList', [])
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      },
      getGardenName (id) {
        const obj = this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.find(data => data.value === id)
          if (obj !== undefined) {
            if (this.$i18n.locale === 'bn') {
                return obj.text
            } else {
                return obj.text
            }
         }
      },
      getFiscalYearList (id) {
      const obj = this.$store.state.CommonService.commonObj.fiscalYearList.find(el => el.value === id)
      if (obj !== undefined) {
            if (this.$i18n.locale === 'bn') {
                return obj.text
            } else {
                return obj.text
            }
         }
      },
      getSchoolName (id) {
        const obj = this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.find(data => data.value === id)
        const schoolObj = this.$store.state.TeaGardenService.commonObj.masterSchoolInformationList.find(item => item.garden_id === obj.value && JSON.parse(item.school_type).includes(this.search.trust_type))
          if (schoolObj !== undefined) {
            if (this.$i18n.locale === 'bn') {
                return schoolObj.text
            } else {
                return schoolObj.text
            }
         }
      }
    }
  }
  </script>
